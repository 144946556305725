
import React from "react"
import { Main } from "../components/layout"
import ProviderWrapper from "../components/pages/provider/ProviderWrapper"

const Provider = () => {
	return (
		<Main>
			<ProviderWrapper />
		</Main>
	)
}

export default Provider
